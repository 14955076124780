import React, { useEffect, useState } from 'react';
import { isEqual, uniqWith } from 'lodash';
import { useDispatch } from 'react-redux';
import { FilterItem, MultiFilter } from 'components/multi-filter/multi-filter';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import { SortOrder } from 'utils/sort-order/sort';
import {
  setPageNumber,
  setFilterChips,
  setHighlightColumns,
  setApplicableFilters,
  Filter,
} from 'store/modules/selected-column/selected-column.reducer';
import { TableColumnProps } from 'components/table/table-component/table-component';
import { TableNamesOfFilters } from 'components/table/table-filters-components/filter-renderer/filter-renderer';

interface Props {
  FilterList: FilterItem[];
  setActiveSortedField: any;
  setActiveSortedOrder: any;
  useActiveSortedFieldSelector: () => string;
  useActiveSortedOrderSelector: () => SortOrder;
  tableStructure: TableColumnProps[];
}

export const GenericFilter = ({
  FilterList,
  setActiveSortedField,
  setActiveSortedOrder,
  useActiveSortedFieldSelector,
  useActiveSortedOrderSelector,
  tableStructure,
}: Props) => {
  const dispatch = useDispatch();

  const selectedColumnFilter = useSelectedColumnSelector();
  const activeSortedOrder = useActiveSortedOrderSelector();
  const activeSortedField = useActiveSortedFieldSelector();
  const { tableFilterChips, applicableFilters } = useSelectedColumnSelector();
  const {
    sorting_field,
    sorting_order,
    filterTitles: identityTitles,
    ...filterChips
  } = tableFilterChips;

  const [filtersObj, setFiltersObj] = useState<Filter>(filterChips);
  const [displayFilters, setDisplayFilters] =
    useState<FilterItem[]>(FilterList);
  const [currentOrder, setCurrentOrder] = useState('');

  useEffect(() => {
    setDisplayFilters(() =>
      FilterList.filter((item) => {
        if (!item?.filterColumns) return false;
        return (
          item?.filterColumns?.findIndex(
            (col) => col === selectedColumnFilter?.previousColumn,
          ) !== -1
        );
      }),
    );
    if (activeSortedField === selectedColumnFilter?.previousColumn) {
      setCurrentOrder(activeSortedOrder === '+' ? 'asc' : 'des');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilterHandler = (
    filterName: string,
    filterValue?: string | boolean | string[],
  ) =>
    setFiltersObj({
      ...filtersObj,
      [filterName]:
        filtersObj[filterName] === filterValue ? undefined : filterValue,
    });

  const showResultsHandler = () => {
    const totalFilters = { ...tableFilterChips, ...filtersObj };
    const filters =
      selectedColumnFilter.previousTable ===
      TableNamesOfFilters.IDENTITIES_USERS_LIST
        ? {
            account_status: totalFilters.account_status
              ? totalFilters.account_status === 'active'
              : undefined,
            team_member: totalFilters.hasOwnProperty('team_member')
              ? totalFilters.team_member
              : undefined,
            internal_account:
              totalFilters.identity_status &&
              totalFilters.identity_status === 'internal'
                ? true
                : undefined,
            external_account:
              totalFilters.identity_status &&
              totalFilters.identity_status === 'external'
                ? true
                : undefined,
            unknown:
              totalFilters.identity_status &&
              totalFilters.identity_status === 'unknown'
                ? true
                : undefined,
            account_type: totalFilters.account_type
              ? totalFilters.account_type
              : undefined,
            only_watched: totalFilters.hasOwnProperty('only_watched')
              ? totalFilters.only_watched
              : undefined,
            only_hidden: totalFilters.hasOwnProperty('only_hidden')
              ? totalFilters.only_hidden
              : undefined,
          }
        : totalFilters;
    Object.keys(filtersObj)
      .filter((colId) => typeof filtersObj[colId] === 'undefined')
      .forEach((colId) => delete filtersObj[colId]);
    const filterTitles = Object.keys(filtersObj).map((colId) => {
      const title =
        FilterList.find((item) => item?.filterId === colId)?.title || '';
      return { id: colId, title, column: selectedColumnFilter?.previousColumn };
    });
    const previousFilterTitles =
      typeof tableFilterChips?.filterTitles === 'object'
        ? tableFilterChips?.filterTitles
        : [];
    const finalFilterTitles = uniqWith(
      [...previousFilterTitles, ...filterTitles],
      (arrVal, othVal) => arrVal.id === othVal.id,
    );
    dispatch(
      setFilterChips({
        ...tableFilterChips,
        ...filtersObj,
        filterTitles: finalFilterTitles,
      }),
    );
    if (isEqual(filters, applicableFilters))
      if (currentOrder === '') {
        if (
          tableFilterChips?.sorting_field ===
          selectedColumnFilter?.previousColumn
        ) {
          applySort('');
          dispatch(
            setFilterChips({
              ...tableFilterChips,
              ...filtersObj,
              filterTitles: finalFilterTitles,
              sorting_order: undefined,
              sorting_field: '',
            }),
          );
        }
        return;
      }
    if (
      selectedColumnFilter.previousTable ===
      TableNamesOfFilters.IDENTITIES_USERS_LIST
    )
      dispatch(setApplicableFilters({ ...filters }));
    else
      dispatch(
        setApplicableFilters({
          ...filters,
          filterTitles: finalFilterTitles,
          sorting_order:
            currentOrder !== ''
              ? currentOrder
              : activeSortedOrder === '+'
              ? 'asc'
              : 'des',
          sorting_field:
            currentOrder !== ''
              ? selectedColumnFilter?.previousColumn
              : activeSortedField,
        }),
      );

    if (currentOrder !== '') {
      if (finalFilterTitles?.length < 1) {
        dispatch(
          setHighlightColumns([
            {
              id: selectedColumnFilter?.previousColumn,
              title:
                tableStructure.find(
                  (item) => item?.id === selectedColumnFilter?.previousColumn,
                )?.title || '',
            },
          ]),
        );
      } else if (
        tableFilterChips?.sorting_field !== selectedColumnFilter?.previousColumn
      ) {
        const requiredCols = finalFilterTitles.filter(
          (item) => item?.column !== tableFilterChips?.sorting_field,
        );
        dispatch(setHighlightColumns([...requiredCols]));
      }
      applySort(currentOrder ? selectedColumnFilter?.previousColumn : '');
      dispatch(
        setFilterChips({
          ...tableFilterChips,
          ...filtersObj,
          filterTitles: finalFilterTitles,
          sorting_order: currentOrder !== '' ? currentOrder : undefined,
          sorting_field: selectedColumnFilter?.previousColumn,
        }),
      );
      if (
        !selectedColumnFilter.highlightColumns.find(
          (col) => col.title === selectedColumnFilter.previousColumn,
        )
      )
        dispatch(
          setHighlightColumns([
            ...selectedColumnFilter?.highlightColumns,
            {
              id: selectedColumnFilter?.previousColumn,
              title:
                tableStructure.find(
                  (item) => item?.id === selectedColumnFilter?.previousColumn,
                )?.title || '',
            },
          ]),
        );
    } else {
      dispatch(
        setHighlightColumns([
          ...selectedColumnFilter?.highlightColumns,
          {
            id: selectedColumnFilter?.previousColumn,
            title:
              tableStructure.find(
                (item) => item?.id === selectedColumnFilter?.previousColumn,
              )?.title || '',
          },
        ]),
      );
    }
    dispatch(setPageNumber(1));
  };

  const applySort = (field: string) => {
    dispatch(setActiveSortedField(field));
    dispatch(
      setActiveSortedOrder(
        currentOrder === 'asc' ? SortOrder.Asc : SortOrder.Desc,
      ),
    );
  };

  const removeFiltersHandler = () => {
    setFiltersObj({});
    dispatch(setFilterChips({}));
    dispatch(setApplicableFilters({}));
    applySort('');
    setCurrentOrder('');
    dispatch(setHighlightColumns([]));
  };

  return (
    <MultiFilter
      showResultsHandler={showResultsHandler}
      setFilterHandler={setFilterHandler}
      filtersObj={filtersObj}
      removeFiltersHandler={removeFiltersHandler}
      filters={displayFilters}
      currentOrder={currentOrder}
      setCurrentOrder={setCurrentOrder}
    />
  );
};
