import Plot from 'react-plotly.js';
import { Stack, Switch, Typography } from '@mui/material';
import { Colors } from '../../../constants/colors';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../../components/providers/api-provider/api-provider';
import { ContainerWithLoader } from '../../../components/container-with-loader/container-with-loader';

export const PieChart = (props: any) => {
  const { id } = props;
  const ApiProvider = useContext(ApiContext);
  const [checked, setChecked] = useState(false);

  const [getTeamChart, { data: teamChartData, isLoading, isError }] =
    ApiProvider.baseApi?.useLazyGetTeamChartQuery();

  useEffect(() => {
    getTeamChart(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const ids = [
    teamChartData?.data.team,
    checked
      ? teamChartData?.data.repos.map((repo: any) => {
          return [
            teamChartData?.data.team + '-' + repo.name,
            teamChartData?.data.team + '-' + repo.name + '-overpriv_score',
            teamChartData?.data.team + '-' + repo.name + '-asset_risk_score',
          ];
        })
      : teamChartData?.data.users.map((user: any) => {
          return [
            teamChartData?.data.team + '-' + user.name,
            teamChartData?.data.team + '-' + user.name + '-overpriv_score',
            teamChartData?.data.team + '-' + user.name + '-asset_risk_score',
          ];
        }),
  ];

  const labels = [
    teamChartData?.data.team,
    checked
      ? teamChartData?.data.repos.map((repo: any) => {
          return [repo.name, 'Overprivilege Score', 'Asset Risk Score'];
        })
      : teamChartData?.data.users.map((user: any) => {
          return [user.name, 'Overprivilege Score', 'User Risk Aggregate'];
        }),
  ];

  const parents = [
    '',
    checked
      ? teamChartData?.data.repos.map((repo: any) => {
          return [
            teamChartData?.data.team,
            teamChartData?.data.team + '-' + repo.name,
            teamChartData?.data.team + '-' + repo.name,
          ];
        })
      : teamChartData?.data.users.map((user: any) => {
          return [
            teamChartData?.data.team,
            teamChartData?.data.team + '-' + user.name,
            teamChartData?.data.team + '-' + user.name,
          ];
        }),
  ];

  const values = [
    teamChartData?.data.team_weightage,
    checked
      ? teamChartData?.data.repos.map((repo: any) => {
          return [
            repo.repo_weightage,
            repo.overpriv_weightage,
            repo.asset_risk_weightage,
          ];
        })
      : teamChartData?.data.users.map((user: any) => {
          return [
            user.user_weightage,
            user.overpriv_weightage,
            user.user_risk_weightage,
          ];
        }),
  ];

  const valuesDisplay = [
    Math.round(teamChartData?.data.team_risk_score * 100) / 100,
    checked
      ? teamChartData?.data.repos.map((repo: any) => {
          return [
            Math.round(repo.asset_risk_score * 100) / 100,
            Math.round(repo.overpriv_score * 100) / 100,
            Math.round(repo.asset_risk_score * 100) / 100,
          ];
        })
      : teamChartData?.data.users.map((user: any) => {
          return [
            Math.round(user.user_risks_agg * 100) / 100,
            Math.round(user.overpriv_score * 100) / 100,
            Math.round(user.user_risks_agg * 100) / 100,
          ];
        }),
  ];

  const data = [
    {
      type: 'sunburst',
      ids: ids.flat(Infinity),
      labels: labels.flat(Infinity),
      parents: parents.flat(Infinity),
      values: values.flat(Infinity),
      textposition: 'inside',
      insidetextorientation: 'radial',
      hovertext: valuesDisplay.flat(Infinity),
      hoverinfo: 'text',
      hovertemplate: '<b>%{label}</b><br /><i>%{hovertext}</i><extra></extra>',
      branchvalues: 'total',
      marker: {
        colors: ['#366b3f'],
        line: { width: 2 },
      },
    },
  ];
  console.log(data);
  var layout = {
    margin: { l: 0, r: 0, b: 0, t: 0 },
    paper_bgcolor: Colors.blackPearl,
  };

  console.log('TEAMCHART DATA', teamChartData);
  return (
    <ContainerWithLoader isLoading={isLoading} isError={isError}>
      <Stack direction="row" spacing={'5px'} alignItems="center">
        <Typography sx={{ color: Colors.whiteTransparent07 }}>Users</Typography>
        <Switch checked={checked} onChange={handleChange} />
        <Typography sx={{ color: Colors.whiteTransparent07 }}>Repos</Typography>
      </Stack>
      {data[0].ids.length !== 1 ? (
        <>
          {/*// @ts-ignore*/}
          <Plot data={data} layout={layout} />
        </>
      ) : (
        <Typography
          sx={{ color: Colors.whiteTransparent04 }}
          variant="h1"
          mt={1}
          p={25}
          textAlign="center"
        >
          No {checked ? 'Repository' : 'User'} Data for the team
        </Typography>
      )}
    </ContainerWithLoader>
  );
};
