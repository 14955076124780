import { TeamDetailsRes } from 'store/apis/types';

export const getReposDetailsString = (team: TeamDetailsRes): string => {
  if (!team?.total_repos) return '0';

  let reposDetailsString = `${team.total_repos} (`;

  if (team?.repos_admin) {
    reposDetailsString += `${team.repos_admin} admin${
      team.repos_admin !== team?.total_repos ? ', ' : ''
    }`;
  }
  if (team?.repos_write) {
    reposDetailsString += `${team.repos_write} write${
      team.repos_write !== team?.total_repos ? ', ' : ''
    }`;
  }
  if (team?.repos_read) {
    reposDetailsString += `${team.repos_read} read${
      team.repos_read !== team?.total_repos ? ', ' : ''
    }`;
  }
  if (team?.repos_maintain) {
    reposDetailsString += `${team.repos_maintain} maintain${
      team.repos_maintain !== team?.total_repos ? ', ' : ''
    }`;
  }
  if (team?.repos_triage) {
    reposDetailsString += `${team.repos_triage} triage`;
  }
  return reposDetailsString + ')';
};
