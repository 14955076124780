import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  Typography,
  Box,
} from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';

import { useIdentitiesUserGroupsTeamsSelector } from 'store/modules/identities/identities.selector';
import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import { useIsPlainModalOpenSelector } from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { paths } from 'constants/routes';
import { setSearchQuery } from 'store/modules/teams/teams.reducer';
import { FontSizes } from 'constants/font-sizes';
import { fontSecondary } from 'constants/font-family';
import { Colors } from 'constants/colors';
import { ReactComponent as ExternalIcon } from 'assets/icons/external.svg';
import { TEAMS_MODAL_TEAM_LINK } from 'constants/test-ids';

export const GroupTeamModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const teams = useIdentitiesUserGroupsTeamsSelector();

  const isGroupTeamOpenModal = useIsPlainModalOpenSelector(
    PlainModals.GROUP_TEAM_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.GROUP_TEAM_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isGroupTeamOpenModal}
      title="Team Name"
      handleCloseModal={handleCloseModal}
      modalWidth="700px"
    >
      <TableContainer
        sx={{
          maxHeight: '750px',
          marginTop: '15px',
          border: `1px solid #0c3c44`,
        }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
          size="small"
        >
          <TableHead sx={{ height: '42px' }}>
            <TableRow>
              <StyledTableHeaderCell>Team Name</StyledTableHeaderCell>
              <StyledTableHeaderCell>Permissions</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams?.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  '&.MuiTableRow-hover:hover': {
                    backgroundColor: Colors.blackPearl,
                  },
                  '&:nth-of-type(odd)': {
                    backgroundColor: Colors.lightTiber,
                  },
                  '&:nth-of-type(even)': {
                    backgroundColor: Colors.tiber,
                  },
                }}
              >
                <StyledTableBodyCell sx={{ borderBottom: '0px' }}>
                  <Typography
                    onClick={() => {
                      navigate(paths.teams);
                      handleCloseModal();
                      dispatch(setSearchQuery(Object.keys(item)[0]));
                    }}
                    sx={{
                      fontSize: FontSizes.fontSize14,
                      fontFamily: fontSecondary,
                      color: Colors.binge,
                      textDecoration: 'none',
                      cursor: 'pointer',
                      textAlign: 'left',
                    }}
                    data-testid={TEAMS_MODAL_TEAM_LINK}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {item.name || item.login || Object.keys(item)[0]}
                      <ExternalIcon style={{ marginLeft: '10px' }} />
                    </Box>
                  </Typography>
                </StyledTableBodyCell>
                <StyledTableBodyCell align="left" sx={{ borderBottom: '0px' }}>
                  {Object.values(item)[0]}
                </StyledTableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalContainer>
  );
};
