import { FilterItem } from 'components/multi-filter/multi-filter';

export const OpenSourceFilterList: FilterItem[] = [
  {
    title: 'Advisories',
    filterId: 'advisories',
    filterColumns: ['advisories'],
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
  {
    title: 'Health Rating',
    filterId: 'lte_total_score',
    filterColumns: ['healthRating'],
    props: [
      {
        label: 'Poor',
        value: '40',
      },
      {
        label: 'Moderate',
        value: '67',
      },
      {
        label: 'Good',
        value: '100',
      },
    ],
  },
  {
    title: 'Language',
    filterId: 'language',
    filterColumns: ['language'],
    props: [
      {
        label: 'Node.js',
        value: 'Node.js',
      },
      {
        label: 'Python',
        value: 'Python',
      },
      {
        label: 'Java',
        value: 'Java',
      },
      {
        label: 'Scala',
        value: 'Scala',
      },
    ],
  },
  {
    title: 'License',
    filterId: 'license',
    filterColumns: ['license'],
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
];
