import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Slide,
  Typography,
} from '@mui/material';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import { VisibilityButton } from 'components/visibility-button/visibility-button';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';
import { SeverityChip } from 'pages/policies/components/severity-chip/severity-chip';
import { ReactComponent as GitHubIcon } from 'assets/icons/github-blue.svg';
import { ReactComponent as EntityGraphIcon } from 'assets/icons/graph.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/cross-circle.svg';
import { ReactComponent as ShieldIcon } from 'assets/icons/shield.svg';
import { ReactComponent as OutlinedUser } from 'assets/icons/user_outline.svg';
import { ReactComponent as BotUser } from 'assets/icons/bot.svg';
import { ReactComponent as OrgAdmin } from 'assets/icons/org-admin.svg';
import { PieChart } from 'pages/teams/pages/PieChart';
import { StyledButton } from 'components/button/button';
import dayjs from 'dayjs';
import React from 'react';
import {
  ExtraCellsObject,
  TableComponent,
} from 'components/table/table-component/table-component';
import { multiToolTableStructure } from 'pages/identities/identity-details/components/identity-info/constants';
import { getReposDetailsString } from 'utils/teams/teams.utils';
import { Circle } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { PackageAdvisoryModal } from 'pages/opensource-dependencies/opensource-dependency-details/components/modals/package-advisory-modal/package-advisory-modal';
import { CustomSvgIcon } from 'components/svg-icon/svg-icon';
import { ReactComponent as NPMIcon } from 'assets/icons/NPM.svg';
import { ReactComponent as PyIcon } from 'assets/icons/Py.svg';
import { ReactComponent as MavenIcon } from 'assets/icons/maven.svg';
import { TransitionProps } from '@mui/material/transitions';
import { TableCellWithLink } from 'components/table/table-components/table-components';
import { StyledTooltip } from 'components/tooltip/tooltip';
import { useNavigate } from 'react-router-dom';
import { paths } from 'constants/routes';
import {
  IDENTITY_MULTI_TOOL_MODAL,
  IDENTITY_MULTI_TOOL_MODAL_LINK,
  IDENTITY_MULTI_TOOL_TABLE,
  INFO_COMPONENT_ENTITY_GRAPH_LINK,
  INFO_COMPONENT_PROFILE_LINK,
  MULTI_TOOL_STATUS_IDENTITY_LINK,
  OPENSOURCE_DETAILS_ADVISORIES_MODAL_LINK,
  OPENSOURCE_DETAILS_PACKAGE_LINK,
  OPENSOURCE_DETAILS_README_LINK,
  OPENSOURCE_INFO_COMPONENT_MODAL,
  RISK_REASON_MODAL_LINK,
} from 'constants/test-ids';

export interface InfoDetailsProps {
  id: string;
  title: string;
  isDate?: boolean;
}

interface Props {
  id?: string | undefined;
  data: any;
  isLoading: boolean;
  isError: boolean;
  infoStructure: InfoDetailsProps[];
  openRiskReasonModal?: () => void;
  goToEntityGraph?: () => void;
  changeItemHide?: any;
  changeItemWatch?: any;
  extraCellsRenderObj?: ExtraCellsObject;
  source?: any;
  marginBottom?: string;
  dataTestId: string;
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const renderStatus = (item: any) => {
  const status = item?.last_login
    ? true
    : item?.status
    ? item.status
    : item?.status_active
    ? item.status_active
    : false;
  return (
    <Typography
      sx={{
        color: status ? Colors.green : Colors.red,
        fontSize: FontSizes.fontSize14,
        fontFamily: fontSecondary,
        lineHeight: '22px',
      }}
    >
      <Circle
        sx={{
          color: status ? Colors.green : Colors.red,
          width: '7px',
          height: '7px',
          marginRight: '7px',
        }}
      />
      {item?.last_login
        ? dayjs(item.last_login).format('L LT')
        : item?.scm_deactivated
        ? 'Suspended'
        : status
        ? 'Active'
        : 'Inactive'}
    </Typography>
  );
};

export const InfoComponent = ({
  id,
  data,
  isLoading,
  isError,
  infoStructure,
  openRiskReasonModal,
  goToEntityGraph,
  changeItemHide,
  changeItemWatch,
  extraCellsRenderObj,
  source,
  marginBottom,
  dataTestId,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [isReadMore, setIsReadMore] = React.useState(false);
  const [openOss, setOpenOss] = React.useState(false);
  const [openPieChart, setOpenPieChart] = React.useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOssOpen = () => {
    setOpenOss(true);
  };

  const handleOssClose = () => {
    setIsReadMore(false);
    setOpenOss(false);
  };

  const handlePieChartOpen = () => {
    setOpenPieChart(true);
  };

  const handlePieChartClose = () => {
    setOpenPieChart(false);
  };

  const renderLogin = (data: any) => {
    return (
      <>
        {data?.login ? (
          <TableCellWithLink
            handleClick={() => {
              handleClose();
              navigate(`${paths.identityDetails}?user=${data?.login}`);
            }}
            isActive={true}
            itemTitle={data.login}
            align="left"
            dataTestId={MULTI_TOOL_STATUS_IDENTITY_LINK}
          />
        ) : (
          '-'
        )}
      </>
    );
  };

  const extraTableCellsRenderObject: ExtraCellsObject = {
    login: renderLogin,
  };

  const RenderDisplayName = () => {
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        data-testid={dataTestId}
      >
        <Box
          width="24px"
          height="24px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: Colors.blackPearl, borderRadius: 6 }}
          mr={1}
        >
          <StyledTooltip
            title={
              <>
                <Typography
                  textTransform={'capitalize'}
                  fontSize={FontSizes.fontSize12}
                >
                  Type: {data?.account_type}
                </Typography>
                <Typography
                  textTransform={'capitalize'}
                  fontSize={FontSizes.fontSize12}
                >
                  Status:{' '}
                  <span
                    style={{
                      color: data.status_active ? Colors.green : Colors.red,
                    }}
                  >
                    {data?.status_active ? 'Active' : 'Inactive'}
                  </span>
                </Typography>
              </>
            }
          >
            <Box>
              <CustomSvgIcon sx={{ ml: '5px', mt: '5px' }}>
                {data.account_type === 'user' ? <OutlinedUser /> : <BotUser />}
              </CustomSvgIcon>
              <Circle
                sx={{
                  color: data.status_active ? Colors.green : Colors.plantation,
                  width: '6px',
                  height: '6px',
                  position: 'relative',
                  bottom: '-7px',
                }}
              />
            </Box>
          </StyledTooltip>
        </Box>
        <TableCellWithLink
          handleClick={() => handleClickOpen()}
          isActive={true}
          itemTitle={data.display_name ? data.display_name : data.login}
          align="left"
          dataTestId={IDENTITY_MULTI_TOOL_MODAL_LINK}
        />
        {data.org_roles && Object.values(data.org_roles).includes('admin') && (
          <StyledTooltip
            title={
              <Box>
                {typeof data.org_roles === 'object' &&
                  Object.keys(data.org_roles).map((org) => (
                    <>
                      {data.org_roles[org] === 'admin' && (
                        <Typography>{org}</Typography>
                      )}
                    </>
                  ))}
              </Box>
            }
          >
            <Box ml={'5px'} mt={'5px'}>
              <OrgAdmin />
            </Box>
          </StyledTooltip>
        )}
      </Box>
    );
  };

  const RenderRiskRating = () => {
    return (
      <>
        {data?.highest_severity && (
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={openRiskReasonModal}
            data-testid={RISK_REASON_MODAL_LINK}
          >
            <SeverityChip
              severityScore={parseInt(data?.highest_severity)}
              showText={true}
            />
          </Box>
        )}
        {!data?.highest_severity && (
          <Box>
            <Typography
              sx={{
                fontSize: FontSizes.fontSize12,
              }}
            >
              -
            </Typography>
          </Box>
        )}
      </>
    );
  };

  const RenderOPScore = () => {
    return (
      <Typography
        sx={{
          color: Colors.white,
          fontSize: FontSizes.fontSize14,
          fontFamily: fontSecondary,
          lineHeight: '22px',
        }}
      >
        {data?.overprivilege_score?.overprivilege_score}
      </Typography>
    );
  };

  const RenderTeamRiskScore = () => {
    return data?.team_risk_score ? (
      <Typography
        sx={{
          color: Colors.binge,
          fontFamily: fontSecondary,
          fontSize: FontSizes.fontSize14,
          gap: '5px',
          cursor: 'pointer',
        }}
        onClick={handlePieChartOpen}
      >
        {data?.team_risk_score}
      </Typography>
    ) : (
      <Typography>-</Typography>
    );
  };

  const RenderLink = ({ title, url }: { title: string; url: string }) => {
    return (
      <Typography>
        <Link
          sx={{
            color: Colors.binge,
            fontFamily: fontSecondary,
            fontSize: FontSizes.fontSize14,
            gap: '5px',
            cursor: 'pointer',
          }}
          rel="noopener"
          href={url}
          target="_blank"
          underline="none"
          data-testid={INFO_COMPONENT_PROFILE_LINK}
        >
          {title === 'profile' ? (
            <GitHubIcon />
          ) : title === 'login' ? (
            `${data?.login}`
          ) : (
            `${data?.name}`
          )}
        </Link>
      </Typography>
    );
  };

  const RenderDate = ({ date }: any) => {
    return (
      <Typography
        sx={{
          color: Colors.white,
          fontSize: FontSizes.fontSize14,
          fontFamily: fontSecondary,
          lineHeight: '22px',
        }}
      >
        {date ? dayjs(date).format('MMM DD, YYYY - LT') : '-'}
      </Typography>
    );
  };

  const RenderEntityGraph = () => {
    return (
      <>
        {goToEntityGraph && (
          <Button
            sx={{
              padding: 0,
              minWidth: 0,
              width: '100%',
            }}
            onClick={() => goToEntityGraph()}
            data-testid={INFO_COMPONENT_ENTITY_GRAPH_LINK}
          >
            <EntityGraphIcon
              style={{
                width: '20px',
                height: '20px',
              }}
            />
          </Button>
        )}
      </>
    );
  };

  const RenderRepositoriesWithAccess = () => {
    return (
      <Typography
        sx={{
          color: Colors.white,
          fontSize: FontSizes.fontSize14,
          fontFamily: fontSecondary,
          lineHeight: '22px',
        }}
      >
        {!data?.total_repos ? '0' : getReposDetailsString(data)}
      </Typography>
    );
  };

  const RenderOSSReadMore = () => {
    return (
      <Typography
        onClick={() => {
          if (data?.readme) {
            handleOssOpen();
            setIsReadMore(true);
          }
        }}
        sx={{
          color: Colors.binge,
          fontFamily: fontSecondary,
          fontSize: FontSizes.fontSize14,
          gap: '5px',
          cursor: data?.readme ? 'pointer' : 'default',
        }}
        data-testid={OPENSOURCE_DETAILS_README_LINK}
      >
        {data?.name ? data?.name : ''}
      </Typography>
    );
  };

  const RenderPackageManager = () => {
    return (
      <Typography>
        <Link
          sx={{
            color: Colors.binge,
            fontFamily: fontSecondary,
            fontSize: FontSizes.fontSize14,
            gap: '5px',
            cursor: 'pointer',
          }}
          rel="noopener"
          href={data?.package_ur}
          data-testid={OPENSOURCE_DETAILS_PACKAGE_LINK}
          target="_blank"
          underline="none"
        >
          <CustomSvgIcon
            sx={{
              fontSize: 18,
              mr: 1,
              verticalAlign: 'text-bottom',
            }}
          >
            {data?.package_manager === 'NPM' && <NPMIcon />}
            {(data?.package_manager === 'PyPI' ||
              data?.package_manager === 'pip') && <PyIcon />}
            {data?.package_manager.toLocaleUpperCase() === 'MAVEN' && (
              <MavenIcon />
            )}
          </CustomSvgIcon>
          {data?.package_manager || ''}
        </Link>
      </Typography>
    );
  };

  const RenderSecurityAdvisories = () => {
    return (
      <Button
        size="small"
        sx={{
          py: 0,
          my: 0,
          height: '16px',
          color: Colors.binge,
          fontFamily: fontSecondary,
          fontSize: FontSizes.fontSize14,
          gap: '5px',
        }}
        startIcon={
          <CustomSvgIcon sx={{ fontSize: FontSizes.fontSize14 }}>
            <ShieldIcon />
          </CustomSvgIcon>
        }
        onClick={() => {
          if (data?.advisories?.length && data?.advisories?.length > 0)
            handleOssOpen();
        }}
        data-testid={OPENSOURCE_DETAILS_ADVISORIES_MODAL_LINK}
      >
        {data?.advisories?.length || 0}
      </Button>
    );
  };

  return (
    <Box
      sx={{
        background: Colors.blackPearl,
        borderRadius: '10px',
        padding: '15px',
        marginTop: '10px',
        marginBottom: marginBottom || '20px',
      }}
    >
      <ContainerWithLoader isError={isError} isLoading={isLoading}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '15px',
          }}
        >
          {infoStructure.map((item) => {
            if (extraCellsRenderObj && extraCellsRenderObj[item.id]) {
              return extraCellsRenderObj[item.id](item);
            }
            return (
              <Box
                sx={{
                  gap: '5px',
                  minWidth: item.id === 'display_name' ? '150px' : null,
                  marginRight: '50px',
                }}
              >
                <Typography
                  sx={{
                    color: Colors.whiteTransparent07,
                    fontFamily: fontSecondary,
                    fontSize: FontSizes.fontSize12,
                    fontWeight: 500,
                    mb: '5px',
                  }}
                >
                  {item.title}
                </Typography>
                {item.isDate ? (
                  <RenderDate date={data ? data[item.id] : ''} />
                ) : item.id === 'display_name' ? (
                  <RenderDisplayName />
                ) : item.id === 'team_risk_score' ? (
                  <RenderTeamRiskScore />
                ) : item.id === 'highest_severity' ? (
                  <RenderRiskRating />
                ) : item.id === 'overprivilege_score' ? (
                  <RenderOPScore />
                ) : item.id === 'profile' ||
                  item.id === 'name' ||
                  item.id === 'login' ? (
                  <RenderLink
                    title={item.id}
                    url={
                      data?.url
                        ? data?.url
                        : `https://github.com/${data?.login}`
                    }
                  />
                ) : item.id === 'status_active' ? (
                  renderStatus(data)
                ) : item.id === 'entity_graph' ? (
                  <RenderEntityGraph />
                ) : item.id === 'total_repos' ? (
                  <RenderRepositoriesWithAccess />
                ) : item.id === 'status' ? (
                  renderStatus(data)
                ) : item.id === 'oss_name' ? (
                  <RenderOSSReadMore />
                ) : item.id === 'package_manager' ? (
                  <RenderPackageManager />
                ) : item.id === 'repository_url' ? (
                  <RenderLink title={'profile'} url={data?.repository_url} />
                ) : item.id === 'advisories' ? (
                  <RenderSecurityAdvisories />
                ) : data && data[item.id] ? (
                  data[item.id]
                ) : (
                  '-'
                )}
              </Box>
            );
          })}
          {id && typeof id === 'string' && (
            <VisibilityButton
              id={id}
              watched={data?.watched}
              hidden={data?.hidden}
              switchWatched={changeItemWatch}
              switchHidden={changeItemHide}
              source={source}
            />
          )}
        </Box>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: Colors.blackPearl,
              padding: '20px 15px',
              maxWidth: '80vw',
              borderRadius: '15px',
              paddingBottom: '5px',
            },
          }}
          data-testid={IDENTITY_MULTI_TOOL_MODAL}
        >
          <DialogTitle sx={{ m: 0, p: 0 }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography
                variant="h1"
                sx={{
                  color: Colors.white,
                  fontFamily: fontSecondary,
                  marginBottom: '0px',
                  fontWeight: 600,
                }}
              >
                Multi-Tool Status
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ width: '16px' }} />
              </IconButton>
            </Box>
          </DialogTitle>
          <TableComponent
            isListLoading={false}
            isListErrored={false}
            isEmptyList={data?.multi_tool_status?.length === 0}
            isListFetching={false}
            dataTestId={IDENTITY_MULTI_TOOL_TABLE}
            list={data?.multi_tool_status || []}
            tableColumns={multiToolTableStructure}
            notPaginated={true}
            extraCellsRenderObj={extraTableCellsRenderObject}
          />
        </Dialog>
        <Dialog
          open={openOss}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleOssClose}
          PaperProps={{
            style: {
              backgroundColor: Colors.blackPearl,
              padding: '20px 15px',
              maxWidth: '80vw',
              borderRadius: '15px',
            },
          }}
          data-testid={OPENSOURCE_INFO_COMPONENT_MODAL}
        >
          <DialogTitle sx={{ m: 0, p: 0 }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              {isReadMore ? (
                <>
                  <Typography
                    sx={{
                      color: Colors.white,
                      fontFamily: fontSecondary,
                      marginTop: '3px',
                      marginBottom: '0px',
                      fontSize: FontSizes.fontSize16,
                    }}
                  >
                    {data?.name} Readme
                  </Typography>
                </>
              ) : (
                <Box display="flex" flexDirection="row">
                  <ShieldIcon
                    style={{
                      width: '18px',
                      marginTop: '3px',
                      height: '18px',
                      color: 'rgba(255, 255, 255, 0.7)',
                      marginRight: '10px',
                    }}
                  />
                  <Typography
                    sx={{
                      color: Colors.white,
                      fontFamily: fontSecondary,
                      marginTop: '3px',
                      marginBottom: '0px',
                      fontSize: FontSizes.fontSize16,
                    }}
                  >
                    Security Advisories ({data?.advisories?.length || 0})
                  </Typography>
                </Box>
              )}

              <IconButton aria-label="close" onClick={handleOssClose}>
                <CloseIcon style={{ width: '16px' }} />
              </IconButton>
            </Box>
          </DialogTitle>
          {isReadMore ? (
            <Box sx={{ background: Colors.white, px: 2, my: 2 }}>
              <ReactMarkdown children={data?.readme ? data?.readme : ''} />
            </Box>
          ) : (
            <PackageAdvisoryModal data={data} />
          )}
        </Dialog>
        <Dialog
          open={openPieChart}
          fullWidth
          maxWidth="md"
          onClose={handlePieChartClose}
          sx={{
            '.MuiDialog-paper': {
              backgroundColor: Colors.blackPearl,
            },
          }}
        >
          <DialogContent sx={{ p: '15px' }}>
            <PieChart id={id} />
          </DialogContent>
          <DialogActions>
            <StyledButton
              onClick={handlePieChartClose}
              variant="outlined"
              color="error"
              size="medium"
            >
              Close
            </StyledButton>
          </DialogActions>
        </Dialog>
      </ContainerWithLoader>
    </Box>
  );
};
