import { BranchesMultiFilter } from 'pages/branches/components/branches-multi-filter/branches-multi-filter';
import {
  setActiveSortedField as setIdentitiesActiveSortedField,
  setActiveSortedOrder as setIdentitiesActiveSortedOrder,
} from 'store/modules/identities/identities.reducer';
import {
  useActiveSortedFieldSelector as useIdentitiesActiveSortedFieldSelector,
  useActiveSortedOrderSelector as useIdentitiesActiveSortedOrderSelector,
} from 'store/modules/identities/identities.selector';
import { IdentitiesFilterList } from 'pages/identities/components/identities-multi-filter/constants';
import { tableStructureWithDisplayName as identitesTableStructure } from 'pages/identities/constants';

import {
  useActiveSortedFieldSelector as useAssetsActiveSortedFieldSelector,
  useActiveSortedOrderSelector as useAssetsActiveSortedOrderSelector,
} from 'store/modules/assets/assets.selector';
import {
  setActiveSortedField as setAssetsActiveSortedField,
  setActiveSortedOrder as setAssetsActiveSortedOrder,
} from 'store/modules/assets/assets.reducer';
import { AssetFilterList } from 'pages/assets/components/assets-multi-filter/constants';
import { tableStructureWithOrg as assetsTableStructure } from 'pages/assets/constants';

import {
  setActiveSortedField as setTeamsActiveSortedField,
  setActiveSortedOrder as setTeamsActiveSortedOrder,
} from 'store/modules/teams/teams.reducer';
import {
  useActiveSortedFieldSelector as useTeamsActiveSortedFieldSelector,
  useActiveSortedOrderSelector as useTeamsActiveSortedOrderSelector,
} from 'store/modules/teams/teams.selector';
import { TeamsFilterList } from 'pages/teams/components/teams-multi-filter/constants';
import { tableStructureWithOrg as teamsTableStructure } from 'pages/teams/constants';
import { tableStructure } from 'pages/secrets/constants';
import { OpenSourceFilterList } from 'pages/opensource-dependencies/components/opensource-dependency-multi-filter/constants';
import { OpenSourceTableStructure } from 'pages/opensource-dependencies/constants';
import { GenericFilter } from 'components/filter/filter';
import {
  AssetContributorsFilterList,
  tableStructureWithLogin,
} from 'pages/assets/asset-details/components/list-of-contributors/constants';

export enum TableNamesOfFilters {
  IDENTITIES_USERS_LIST = 'identities_users_list',
  ASSETS_REPOS_LIST = 'assets_repos_list',
  BRANCHES_LIST = 'branches_list',
  TEAMS_LIST = 'teams_list',
  IDENTITIES_ACCESS = 'identities_access',
  ASSETS_DETAILS_CONTRIBUTORS = 'assets_details_contributors',
  ASSET_DETAILS_TEAMS = 'asset_details_teams',
  ASSETS_NESTED_BRANCHES = 'assets_nested_branches',
  OPENSOURCE_REPOSITORIES_LIST = 'opensource-repositories_list',
  DETECTED_SECRETS_LIST = 'detected_secrets_list',
  POLICY_BUILDER = 'policy-builder',
  POLICIES_LIBRARY = 'policies-library',
  POLICIES_CUSTOM_POLICIES = 'policies-custom-polices',
  JIT_PENDING_REQUESTS = 'jit_pending',
  RECOMMENDATIONS_LIST = 'recommendations_list',
}

export const FilterRenderer = (props: any) => {
  switch (props.table) {
    case 'identities_users_list':
      return (
        <GenericFilter
          FilterList={IdentitiesFilterList}
          setActiveSortedField={setIdentitiesActiveSortedField}
          setActiveSortedOrder={setIdentitiesActiveSortedOrder}
          useActiveSortedFieldSelector={useIdentitiesActiveSortedFieldSelector}
          useActiveSortedOrderSelector={useIdentitiesActiveSortedOrderSelector}
          tableStructure={identitesTableStructure}
        />
      );
    case 'assets_repos_list':
      return (
        <GenericFilter
          FilterList={AssetFilterList}
          setActiveSortedField={setAssetsActiveSortedField}
          setActiveSortedOrder={setAssetsActiveSortedOrder}
          useActiveSortedFieldSelector={useAssetsActiveSortedFieldSelector}
          useActiveSortedOrderSelector={useAssetsActiveSortedOrderSelector}
          tableStructure={assetsTableStructure}
        />
      );
    case 'branches_list':
      return <BranchesMultiFilter />;
    case 'teams_list':
      return (
        <GenericFilter
          FilterList={TeamsFilterList}
          setActiveSortedField={setTeamsActiveSortedField}
          setActiveSortedOrder={setTeamsActiveSortedOrder}
          useActiveSortedFieldSelector={useTeamsActiveSortedFieldSelector}
          useActiveSortedOrderSelector={useTeamsActiveSortedOrderSelector}
          tableStructure={teamsTableStructure}
        />
      );
    case 'identities_access':
      return (
        <GenericFilter
          FilterList={IdentitiesFilterList}
          setActiveSortedField={setIdentitiesActiveSortedField}
          setActiveSortedOrder={setIdentitiesActiveSortedOrder}
          useActiveSortedFieldSelector={useIdentitiesActiveSortedFieldSelector}
          useActiveSortedOrderSelector={useIdentitiesActiveSortedOrderSelector}
          tableStructure={identitesTableStructure}
        />
      );
    case 'assets_details_contributors':
      return (
        <GenericFilter
          FilterList={AssetContributorsFilterList}
          setActiveSortedField={setAssetsActiveSortedField}
          setActiveSortedOrder={setAssetsActiveSortedOrder}
          useActiveSortedFieldSelector={useAssetsActiveSortedFieldSelector}
          useActiveSortedOrderSelector={useAssetsActiveSortedOrderSelector}
          tableStructure={tableStructureWithLogin}
        />
      );
    case 'assets_nested_branches':
      return <BranchesMultiFilter />;
    case 'opensource-repositories_list':
      return (
        <GenericFilter
          FilterList={OpenSourceFilterList}
          setActiveSortedField={setAssetsActiveSortedField}
          setActiveSortedOrder={setAssetsActiveSortedOrder}
          useActiveSortedFieldSelector={useAssetsActiveSortedFieldSelector}
          useActiveSortedOrderSelector={useAssetsActiveSortedOrderSelector}
          tableStructure={OpenSourceTableStructure}
        />
      );
    case 'detected_secrets_list':
      return (
        <GenericFilter
          FilterList={[]}
          setActiveSortedField={setAssetsActiveSortedField}
          setActiveSortedOrder={setAssetsActiveSortedOrder}
          useActiveSortedFieldSelector={useAssetsActiveSortedFieldSelector}
          useActiveSortedOrderSelector={useAssetsActiveSortedOrderSelector}
          tableStructure={tableStructure}
        />
      );
    case 'policy-builder':
      return <></>;
    case 'policies-library':
      return <></>;
    case 'policies-custom-polices':
      return <></>;
    case 'recommendations_list':
      return <></>;
    default:
      return (
        <GenericFilter
          FilterList={IdentitiesFilterList}
          setActiveSortedField={setIdentitiesActiveSortedField}
          setActiveSortedOrder={setIdentitiesActiveSortedOrder}
          useActiveSortedFieldSelector={useIdentitiesActiveSortedFieldSelector}
          useActiveSortedOrderSelector={useIdentitiesActiveSortedOrderSelector}
          tableStructure={identitesTableStructure}
        />
      );
  }
};
