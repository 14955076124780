import { FilterItem } from 'components/multi-filter/multi-filter';

export const AssetFilterList: FilterItem[] = [
  {
    title: 'Critical',
    filterId: 'critical',
    filterColumns: ['name'],
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
  {
    title: 'Status',
    filterId: 'status',
    filterColumns: ['status'],
    props: [
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Stale',
        value: 'inactive',
      },
      {
        label: 'Archived',
        value: 'archived',
      },
    ],
  },
  {
    title: 'Production',
    filterId: 'only_production',
    filterColumns: ['name'],
    props: [
      {
        label: 'Production',
        value: true,
      },
      {
        label: 'Non Production',
        value: false,
      },
    ],
  },
  {
    title: 'Watched',
    filterId: 'only_watched',
    filterColumns: ['name'],
    props: [
      {
        label: 'Watched',
        value: true,
      },
      {
        label: 'Not Watched',
        value: false,
      },
    ],
  },
  {
    title: 'Hidden',
    filterId: 'only_hidden',
    filterColumns: ['name'],
    props: [
      {
        label: 'Hidden',
        value: true,
      },
      {
        label: 'Not Hidden',
        value: false,
      },
    ],
  },
];
