import { InfoDetailsProps } from 'components/info-component/info-component';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructureWithLogin: TableColumnProps[] = [
  {
    id: 'login',
    title: 'Account Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'highest_severity',
    title: 'Risk Rating',
    withSort: true,
    position: 'left',
  },
  {
    id: 'scm_source',
    title: 'Source',
    withSort: true,
    position: 'left',
  },
  {
    id: 'overprivilege_score',
    title: 'OverPrivilege score ',
    withSort: true,
    position: 'left',
  },
  {
    id: 'repos_count',
    title: 'Total',
    position: 'left',
  },
  {
    id: 'count_admin_permissions',
    title: 'Admins',
    position: 'left',
  },
  {
    id: 'status_active',
    title: 'Account Status',
    withSort: true,
    position: 'left',
  },
  {
    id: 'identity_status',
    title: 'Identity Status',
    withSort: true,
    position: 'left',
    sortColumn: 'external',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    withSort: true,
    position: 'left',
    isDate: true,
  },
];

export const tableStructureWithDisplayName: TableColumnProps[] = [
  {
    id: 'display_name',
    title: 'Account Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'highest_severity',
    title: 'Risk Rating',
    withSort: true,
    position: 'left',
  },
  {
    id: 'scm_source',
    title: 'Source',
    withSort: true,
    position: 'left',
  },
  {
    id: 'overprivilege_score',
    title: 'OverPrivilege score ',
    withSort: true,
    position: 'left',
  },
  {
    id: 'repos_count',
    title: 'Total',
    position: 'left',
  },
  {
    id: 'count_admin_permissions',
    title: 'Admins',
    position: 'left',
  },
  {
    id: 'identity_status',
    title: 'Identity Status',
    withSort: true,
    position: 'left',
    sortColumn: 'external',
  },
  {
    id: 'graph',
    title: 'Graph',
    position: 'left',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    position: 'left',
    isDate: true,
  },
];

export const identityInfoStructure: InfoDetailsProps[] = [
  {
    id: 'display_name',
    title: 'User',
  },
  {
    id: 'highest_severity',
    title: 'Risk Rating',
  },
  {
    id: 'overprivilege_score',
    title: 'OverPrivilege Score',
  },
  {
    id: 'profile',
    title: 'Profile',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    isDate: true,
  },
  {
    id: 'entity_graph',
    title: 'Graph',
  },
];

export const selfInfoStructure: InfoDetailsProps[] = [
  {
    id: 'self_name',
    title: 'User',
  },
  {
    id: 'profile',
    title: 'Profile',
  },
];
