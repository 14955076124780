import { InfoComponent } from 'components/info-component/info-component';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { ApiContext } from 'components/providers/api-provider/api-provider';

import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  PlainModals,
  closeModalByNameAction,
} from 'store/modules/modals/modals.reducer';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { advisoriesInfoStructure } from './constants';
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from '@mui/material';
import { Colors } from 'constants/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactMarkdown from 'react-markdown';
import {
  OPENSOURCE_ADVISORIES_INFO_COMPONENT,
  OPENSOURCE_ADVISORIES_MODAL_ACCORDIAN,
} from 'constants/test-ids';

export const AdvisoriesModal = () => {
  const dispatch = useDispatch();
  const metadata = useGetModalMetaData();
  const apiContext = useContext(ApiContext);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const { pkg, version, pkgManager } = metadata;

  const isOpenModal = useIsPlainModalOpenSelector(PlainModals.ADVISORIES_MODAL);

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.ADVISORIES_MODAL));
  };

  const { data, isLoading, isError } =
    apiContext.baseApi.useGetExternalPackageVulnerabilityDetailsQuery({
      pkg,
      version,
      pkgManager,
    });

  // const renderSeverityCell = (item: any) => {
  //   let severity = 0;
  //   switch (item.severity) {
  //     case 'CRITICAL':
  //       severity = 5;
  //       break;
  //     case 'HIGH':
  //       severity = 4;
  //       break;
  //     case 'MODERATE':
  //       severity = 3;
  //       break;
  //     case 'LOW':
  //       severity = 2;
  //       break;
  //     case 'INFO':
  //       severity = 1;
  //       break;
  //     default:
  //       severity = 0;
  //       break;
  //   }
  //   return <SeverityIcon severityScore={severity} iconSize="20px" />;
  // };

  const renderSeverityCell = (item: any) => {
    let chipColor = '';
    let chipBackground = '';
    switch (item.severity?.toLocaleUpperCase()) {
      case 'CRITICAL':
        chipColor = '#F85455';
        chipBackground = 'rgba(248, 84, 85, 0.2);';
        break;
      case 'HIGH':
        chipColor = '#F77A63';
        chipBackground = 'rgba(247, 122, 99, 0.2)';

        break;
      case 'MODERATE':
        chipColor = '#FF9D2B';
        chipBackground = 'rgba(255, 157, 43, 0.2)';

        break;
      case 'LOW':
        chipColor = '#FDD60D';
        chipBackground = 'rgba(253, 214, 13, 0.2)';
        break;
      default:
        chipColor = '#FDD60D';
        chipBackground = 'rgba(253, 214, 13, 0.2)';
        break;
    }
    return (
      <Chip
        size="small"
        sx={{
          backgroundColor: chipBackground,
          color: chipColor,
          border: `1.8px solid ${chipBackground}`,
          px: 1,
        }}
        label={item.severity?.toLocaleUpperCase()}
        variant="outlined"
      />
    );
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <ModalContainer
      isOpenModal={isOpenModal}
      handleCloseModal={handleCloseModal}
      title="Package Vulnerabilities Details"
      modalWidth="850px"
      modalHeight="600px"
      alignItems="start"
    >
      <Box sx={{ height: '100%', width: '100%' }}>
        <InfoComponent
          data={data}
          isLoading={isLoading}
          isError={isError}
          infoStructure={advisoriesInfoStructure}
          marginBottom="0px"
          dataTestId={OPENSOURCE_ADVISORIES_INFO_COMPONENT}
        />
        {/* <TableComponent
        isListLoading={isLoading}
        isListErrored={isError}
        isListFetching={isFetching}
        isEmptyList={!data?.advisories?.length}
        list={data?.advisories}
        tableColumns={advisoriesTableStructure}
        extraCellsRenderObj={extraCellsRenderObj}
        notPaginated
      /> */}

        {/* <Box marginTop={'10px'}>
        <Typography
          fontSize={FontSizes.fontSize14}
          color={Colors.whiteTransparent07}
        >
          Summary
        </Typography>
        <Typography fontSize={FontSizes.fontSize16} color={Colors.white}>
          {data?.summary || ''}
        </Typography>
      </Box>

      <Box marginTop={'10px'}>
        <Typography
          fontSize={FontSizes.fontSize14}
          color={Colors.whiteTransparent07}
        >
          Fixed Version
        </Typography>
        <Typography fontSize={FontSizes.fontSize16} color={Colors.white}>
          {data?.fixed_version || ''}
        </Typography>
      </Box> */}
        <Box sx={{ width: '100%', overflowY: 'auto', height: '300px' }}>
          {data?.advisories.map((advisory: any, index: number) => (
            <Accordion
              key={index}
              expanded={expanded === advisory?.CVE}
              onChange={handleChange(advisory?.CVE)}
              sx={{ maxWidth: '100%', width: '100%', marginBottom: '10px' }}
              data-testid={OPENSOURCE_ADVISORIES_MODAL_ACCORDIAN}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: Colors.white }} />}
                aria-controls={`panel${index + 1}bh-content`}
                id={`panel${index + 1}bh-header`}
                sx={{
                  backgroundColor:
                    index % 2 === 0 ? Colors.tiber : Colors.lightTiber,
                  '&:hover': {
                    backgroundColor: Colors.blackPearl,
                  },
                }}
              >
                <Typography mr={2}>{renderSeverityCell(advisory)}</Typography>
                <Typography mr={2}>{advisory?.CVE}</Typography>
                <Typography mr={2}>{advisory?.summary}</Typography>
                <Typography>
                  Fixed Version:{' '}
                  <span style={{ fontWeight: 500 }}>
                    {advisory?.fixed ? advisory.fixed : 'No fix found'}
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: Colors.sectionBackground,
                  color: Colors.white,
                }}
              >
                <ReactMarkdown
                  children={advisory?.details ? advisory?.details : ''}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </ModalContainer>
  );
};
