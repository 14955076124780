import { FilterItem } from 'components/multi-filter/multi-filter';

export const IdentitiesFilterList: FilterItem[] = [
  {
    title: 'Account status',
    filterId: 'account_status',
    filterColumns: ['display_name', 'login'],
    props: [
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Inactive',
        value: 'inactive',
      },
    ],
  },
  {
    title: 'Identity Status',
    filterId: 'identity_status',
    filterColumns: ['identity_status'],
    props: [
      {
        label: 'External',
        value: 'external',
      },
      {
        label: 'Internal',
        value: 'internal',
      },
      {
        label: 'Unknown',
        value: 'unknown',
      },
    ],
  },
  {
    title: 'Team member',
    filterId: 'team_member',
    props: [
      {
        label: 'Member',
        value: true,
      },
      {
        label: 'Not a member',
        value: false,
      },
    ],
  },
  {
    title: 'Account type',
    filterId: 'account_type',
    filterColumns: ['display_name', 'login'],
    props: [
      {
        label: 'User',
        value: 'user',
      },
      {
        label: 'App',
        value: 'app',
      },
      {
        label: 'Service',
        value: 'service',
      },
    ],
  },
  {
    title: 'Watched',
    filterId: 'only_watched',
    props: [
      {
        label: 'Watched',
        value: true,
      },
      {
        label: 'Not Watched',
        value: false,
      },
    ],
  },
  {
    title: 'Hidden',
    filterId: 'only_hidden',
    props: [
      {
        label: 'Hidden',
        value: true,
      },
      {
        label: 'Not Hidden',
        value: false,
      },
    ],
  },
];
