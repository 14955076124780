import { InfoDetailsProps } from 'components/info-component/info-component';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const OpenSourceTableStructure: TableColumnProps[] = [
  {
    id: 'package',
    title: 'Package Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'last_release',
    title: 'Last Release',
    withSort: true,
    isDate: true,
    position: 'left',
  },
  {
    id: 'license',
    title: 'License',
    withSort: true,
    position: 'left',
  },
  {
    id: 'advisory',
    title: 'Security Advisory',
    withSort: true,
    position: 'left',
  },
  {
    id: 'total_score',
    title: 'Health Rating',
    withSort: true,
    position: 'left',
  },
  {
    id: 'language',
    title: 'Language',
    withSort: true,
    position: 'left',
  },
  {
    id: 'repositories',
    title: 'Repositories',
    withSort: true,
    position: 'left',
  },
];

export const openSourceInfoStructure: InfoDetailsProps[] = [
  {
    id: 'oss_name',
    title: 'Open Source',
  },
  {
    id: 'last_release',
    title: 'Last Release',
    isDate: true,
  },
  {
    id: 'license',
    title: 'License',
  },
  {
    id: 'package_manager',
    title: 'Package Manager',
  },
  {
    id: 'repository_url',
    title: 'Source Code',
  },
  {
    id: 'advisories',
    title: 'Security Advisories',
  },
];
