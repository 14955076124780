import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { useDeepCompareEffect } from './use-deep-compare-effect';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import { useDispatch } from 'react-redux';
import {
  setPageNumber,
  setTotalItems,
} from 'store/modules/selected-column/selected-column.reducer';

interface Props {
  baseApiCallback: any;
  refreshData?: boolean;
  sortBy?: string;
  id?: string;
  severity?: string;
  tableFilters?:
    | {
        [key: string]: any;
      }
    | string;
  searchQuery?: string;
  startDate?: string;
  endDate?: string;
  itemsPerPage?: number;
  requestData?: any;
  infiniteScroll?: boolean;
}

interface ReturnProps {
  dataList: any[];
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  isNoFindings: boolean;
  total: number;
  label: string;
  itemsPage: number;
  page: number;
  error: any;
}

export const useGetResponseDataWithPagination = ({
  baseApiCallback,
  refreshData = false,
  sortBy,
  id,
  severity,
  tableFilters,
  searchQuery,
  startDate,
  endDate,
  requestData,
  infiniteScroll,
}: Props): ReturnProps => {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState<any[]>([]);

  const pageSize = useSelectedColumnSelector().itemsPerPage;
  const page = useSelectedColumnSelector().page;

  const [
    getDataList,
    { data, isLoading, isError, isFetching, isSuccess, error },
  ] = baseApiCallback({ id });

  useEffect(() => {
    // if (
    //   sortBy !== prevSortByValue ||
    //   // searchQuery !== prevSearchQuery ||
    //   startDate !== prevStartDate ||
    //   endDate !== prevEndDate
    // ) {
    //   dispatch(setPageNumber(1));
    //   dispatch(setItemsPerPage(10));
    //   return;
    // }
    getDataList({
      pageNum: page,
      sortBy,
      id,
      tableFilters,
      searchQuery: searchQuery || undefined,
      startDate,
      severity,
      endDate,
      itemsPerPage: pageSize,
      ...requestData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getDataList,
    page,
    sortBy,
    id,
    tableFilters,
    searchQuery,
    startDate,
    endDate,
    pageSize,
  ]);

  useEffect(() => {
    dispatch(setPageNumber(1));
    getDataList({
      pageNum: page,
      sortBy,
      id,
      tableFilters,
      severity,
      searchQuery: searchQuery || undefined,
      startDate,
      endDate,
      itemsPerPage: pageSize,
      ...requestData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  useDeepCompareEffect(() => {
    if (
      data?.data &&
      !infiniteScroll &&
      (data?.page === page || data?.page === undefined)
    ) {
      setDataList(data.data);
    } else if (
      data?.data &&
      infiniteScroll &&
      (data?.page === page || data?.page === undefined)
    ) {
      setDataList((prevState) =>
        page === 1 ? data.data : [...prevState, ...data.data],
      );
    }
    dispatch(setTotalItems(data?.total));
  }, [data?.data, page]);

  return {
    dataList,
    total: data?.total ?? undefined,
    itemsPage: data?.items_page ?? undefined,
    page: data?.page ?? undefined,
    label: data?.label ?? undefined,
    isLoading,
    isError,
    isFetching,
    isNoFindings: isEmpty(data?.data) && isSuccess && !isLoading,
    error,
  };
};
