export const MAIN_WRAPPER = 'main-wrapper';

export const GLOBAL_SEARCH_BUTTON = 'global-search-button';
export const USER_PROFILE_BUTTON = 'user-profile-button';
export const USER_SIGNOUT_BUTTON = 'user-signout-button';

export const NAVIGATION_DRAWER_EXPAND_BUTTON =
  'navigation-drawer-expand-button';
export const NAVIGATION_DRAWER_PANEL = 'navigation-drawer-panel';
export const NAVIGATION_DASHBOARD_BUTTON = 'navigation-dashboard-button';
export const NAVIGATION_POLICIES_BUTTON = 'navigation-policies-button';
export const NAVIGATION_REPORTING_BUTTON = 'navigation-reporting-button';
export const NAVIGATION_REPORTING_ALERTS_BUTTON =
  'navigation-reporting-alerts-button';
export const NAVIGATION_REPORTING_HISTORY_BUTTON =
  'navigation-reporting-history-button';
export const NAVIGATION_COMPLIANCE_BUTTON = 'navigation-compliance-button';
export const NAVIGATION_RESOURCES_BUTTON = 'navigation-resources-button';
export const NAVIGATION_RESOURCES_IDENTITIES_BUTTON =
  'navigation-resources-identities-button';
export const NAVIGATION_RESOURCES_ASSETS_BUTTON =
  'navigation-resources-assets-button';
export const NAVIGATION_RESOURCES_TEAMS_BUTTON =
  'navigation-resources-teams-button';
export const NAVIGATION_RESOURCES_CONSOLE_BUTTON =
  'navigation-resources-console-button';
export const NAVIGATION_RESOURCES_GRAPH_BUTTON =
  'navigation-resources-graph-button';
export const NAVIGATION_RECOMMENDATIONS_BUTTON =
  'navigation-recommendations-button';
export const NAVIGATION_OPENSOURCE_BUTTON = 'navigation-opensource-button';
export const NAVIGATION_FINDINGS_BUTTON = 'navigation-findings-button';
export const NAVIGATION_SAST_BUTTON = 'navigation-sast-button';
export const NAVIGATION_IAC_BUTTON = 'navigation-iac-button';
export const NAVIGATION_SECRETS_BUTTON = 'navigation-secrets-button';
export const NAVIGATION_SETTINGS_BUTTON = 'navigation-settings-button';
export const NAVIGATION_SETTINGS_USERMANAGEMENT_BUTTON =
  'navigation-settings-usermanagement-button';
export const NAVIGATION_SETTINGS_CONNECTORS_BUTTON =
  'navigation-settings-connectors-button';
export const NAVIGATION_SETTINGS_TENANTS_BUTTON =
  'navigation-settings-tenants-button';
export const NAVIGATION_SETTINGS_WATCH_HIDE_BUTTON =
  'navigation-settings-watch-hide-button';
export const NAVIGATION_SETTINGS_RECOMMENDATION_STATUS_BUTTON =
  'navigation-settings-recommendation-status-button';
export const NAVIGATION_SIGNOUT_BUTTON = 'navigation-signout-button';

export const ALERTS_PAGE_HEADER = 'alerts-page-header';
export const ASSETS_PAGE_HEADER = 'assets-page-header';
export const BRANCHES_PAGE_HEADER = 'branches-page-header';
export const COMPLIANCE_PAGE_HEADER = 'compliance-page-header';
export const CONNECTORS_PAGE_HEADER = 'connectors-page-header';
export const DASHBOARD_PAGE_HEADER = 'dashboard-page-header';
export const ENTITY_GRAPH_PAGE_HEADER = 'entity-graph-page-header';
export const IDENTITIES_PAGE_HEADER = 'identities-page-header';
export const PAGE_NOT_FOUND = 'page-not-found';
export const OPENSOURCE_PAGE_HEADER = 'opensource-page-header';
export const POLICIES_PAGE_HEADER = 'policies-page-header';
export const RECOMMENDATION_MEMBERS_PAGE_HEADER =
  'recommendation-members-page-header';
export const RECOMMENDATION_DETAILS_PAGE_HEADER =
  'recommendation-details-page-header';
export const RECOMMENDATION_TEAMS_PAGE_HEADER =
  'recommendation-teams-page-header';
export const RECOMMENDATION_TEAM_DETAILS_PAGE_HEADER =
  'recommendation-team-details-page-header';
export const RECOMMENDATION_USER_DETAILS_PAGE_HEADER =
  'recommendation-user-details-page-header';
export const USER_RECOMMENDATIONS_PAGE_HEADER =
  'user-recommendations-page-header';
export const RECOMMENDATIONS_PAGE_HEADER = 'recommendations-page-header';
export const RECOMMENDATION_STATUS_PAGE_HEADER =
  'recommendation-status-page-header';
export const REPORTING_PAGE_HEADER = 'reporting-page-header';
export const SECRETS_PAGE_HEADER = 'secrets-page-header';
export const SAST_PAGE_HEADER = 'sast-page-header';
export const SAST_MAIN_TABLE = 'sast-main-table';
export const IAC_PAGE_HEADER = 'iac-page-header';
export const IAC_MAIN_TABLE = 'iac-main-table';
export const TEAMS_PAGE_HEADER = 'teams-page-header';
export const TENANT_SETTINGS_PAGE_HEADER = 'tenant-settings-page-header';
export const WATCH_HIDE_PAGE_HEADER = 'watch-hide-page-header';
export const USER_MANAGEMENT_PAGE_HEADER = 'user-management-page-header';

export const IDENTITY_MULTI_TOOL_TABLE = 'identity-multi-tool-table';
export const ALERTS_MAIN_TABLE = 'alerts-main-table';
export const ASSETS_MAIN_TABLE = 'assets-main-table';
export const ASSET_DETAILS_ACTIVITY_TABLE = 'asset-details-activity-table';
export const ASSET_DETAILS_BRANCHES_TABLE = 'asset-details-branches-table';
export const ASSET_RECOMMENDATIONS_ACTION_DROPDOWN =
  'asset-recommendations-action-dropdown';
export const ASSET_RECOMMENDATIONS_ACTION_ITEM =
  'asset-recommendations-action-item';
export const ASSET_DETAILS_COMPLIANCE_TABLE = 'asset-details-compliance-table';
export const ASSET_DETAILS_CONTAINERS_TABLE = 'asset-details-containers-table';
export const ASSET_DETAILS_EXTERNAL_PACKAGES_TABLE =
  'asset-details-external-packages-table';
export const ASSET_DETAILS_CONTRIBUTORS_TABLE =
  'asset-details-contributors-table';
export const ASSET_DETAILS_TEAMS_TABLE = 'asset-details-teams-table';
export const ASSET_DETAILS_PIPELINE_TABLE = 'asset-details-pipeline-table';
export const BRANCHES_MAIN_TABLE = 'branches-main-table';
export const COMPLIANCE_OVERVIEW_TABLE = 'compliance-overview-table';
export const COMPLIANCE_DETAILS_TABLE = 'compliance-details-table';
export const COMPLIANCE_MANUAL_POLICY_DETAILS_TABLE =
  'compliance-manual-policy-details-table';
export const COMPLIANCE_POLICY_DETAILS_TABLE =
  'compliance-policy-details-table';
export const GLOBAL_SEARCH_OPENAI_TABLE = 'global-search-openai-table';
export const GLOBAL_SEARCH_CELL_RENDERER_TABLE =
  'global-search-cell-renderer-table';
export const GLOBAL_SEARCH_RESPONSE_TABLE = 'global-search-response-table';
export const IDENTITIES_MAIN_TABLE = 'identities-main-table';
export const IDENTITIES_INSIGHTS_RISK_TABLE = 'identities-insights-risk-table';
export const IDENTITY_DETAILS_ACCESS_TABLE = 'identity-details-access-table';
export const IDENTITY_DETAILS_ACTIVITY_TABLE =
  'identity-details-activity-table';
export const IDENTITY_DETAILS_ALERTS_TABLE = 'identity-details-alerts-table';
export const IDENTITY_DETAILS_AUDIT_TRAIL_TABLE =
  'identity-details-audit-trail-table';
export const IDENTITY_DETAILS_RECOMMENDATIONS_TABLE =
  'identity-details-recommendations-table';
export const IDENTITY_DETAILS_RISK_REASON_TABLE =
  'identity-details-risk-reason-table';
export const IDENTITY_DETAILS_OPENSOURCE_TABLE =
  'identity-details-opensource-table';
export const IDENTITY_DETAILS_PIPELINE_TABLE =
  'identity-details-pipeline-table';
export const IDENTITY_DETAILS_SECRETS_TABLE = 'identity-details-secrets-table';
export const IDENTITY_DETAILS_JIT_LISTING_TABLE =
  'identity-details-jit-listing-table';
export const IDENTITY_DETAILS_JIT_REQUESTS_TABLE =
  'identity-details-jit-requests-table';
export const OPENSOURCE_MAIN_TABLE = 'opensource-main-table';
export const OPENSOURCE_DETAILS_CONTRIBUTORS_TABLE =
  'opensource-details-contributors-table';
export const OPENSOURCE_DETAILS_PACKAGE_ADVISORIES_TABLE =
  'opensource-details-package-advisories-table';
export const OPENSOURCE_DETAILS_REPOSITORIES_TABLE =
  'opensource-details-repositories-table';
export const CUSTOM_POLICIES_MAIN_TABLE = 'custom-policies-main-table';
export const POLICY_LIBRARY_MAIN_TABLE = 'policy-library-main-table';
export const RECOMMENDATION_TEAM_MEMBERS_TABLE =
  'recommendation-team-members-table';
export const RECOMMENDATION_TEAM_MEMBER_DETAILS_TABLE =
  'recommendation-team-member-details-table';
export const RECOMMENDATION_TEAMS_TABLE = 'recommendation-teams-table';
export const RECOMMENDATION_TEAM_DETAILS_TABLE =
  'recommendation-team-details-table';
export const RECOMMENDATION_USERS_TABLE = 'recommendation-users-table';
export const RECOMMENDATION_USER_DETAILS_TABLE =
  'recommendation-user-details-table';
export const RECOMMENDATIONS_MULTI_TEAMS_TABLE =
  'recommendations-multi-teams-table';
export const RECOMMENDATIONS_OVERVIEW_TABLE = 'recommendations-overview-table';
export const RECOMMENDATION_DETAILS_TEAMS_TABLE =
  'recommendation-details-teams-table';
export const RECOMMENDATION_DETAILS_PERMISSIONS_TABLE =
  'recommendation-details-permissions-table';
export const RECOMMENDATIONS_STATUS_TABLE = 'recommendations-status-table';
export const REPORTING_MAIN_TABLE = 'reporting-main-table';
export const REPORTING_CSV_VIEWER_TABLE = 'reporting-csv-viewer-table';
export const SECRETS_MAIN_TABLE = 'secrets-main-table';
export const TEAMS_MAIN_TABLE = 'teams-main-table';
export const TEAM_DETAILS_MAPPINGS_TABLE = 'team-details-mappings-table';
export const TEAM_DETAILS_MEMBERS_TABLE = 'team-details-members-table';
export const TEAM_DETAILS_ASSETS_TABLE = 'team-details-assets-table';
export const TEAM_DETAILS_RECOMMENDATIONS_TABLE =
  'team-details-recommendations-table';
export const TEAM_DETAILS_IDLE_USERS_TABLE = 'team-details-idle-users-table';
export const WATCH_HIDE_MAIN_TABLE = 'watch-hide-main-table';
export const USER_MANAGEMENT_MAIN_TABLE = 'user-management-main-table';

export const ASSET_DETAILS_INFO_COMPONENT = 'asset-details-info-component';
export const COMPLIANCE_DETAILS_INFO_COMPONENT =
  'compliance-details-info-component';
export const COMPLIANCE_POLICY_DETAILS_INFO_COMPONENT =
  'compliance-policy-details-info-component';
export const IDENTITY_DETAILS_INFO_COMPONENT =
  'identity-details-info-component';
export const COMMIT_DETAILS_INFO_COMPONENT = 'commit-details-info-component';
export const OPENSOURCE_DETAILS_INFO_COMPONENT =
  'opensource-details-info-component';
export const OPENSOURCE_ADVISORIES_INFO_COMPONENT =
  'opensource-advisories-info-component';
export const OPENSOURCE_CONTRIBUTOR_REPUTATION_INFO_COMPONENT =
  'opensource-contributor-reputation-info-component';
export const RECOMMENDATION_DETAILS_INFO_COMPONENT =
  'recommendation-details-info-component';
export const TEAM_DETAILS_INFO_COMPONENT = 'team-details-info-component';

export const TABLE_COMPONENT_ENTITY_GRAPH_LINK =
  'table-component-entity-graph-link';
export const INFO_COMPONENT_ENTITY_GRAPH_LINK =
  'info-component-entity-graph-link';
export const MULTI_TOOL_STATUS_IDENTITY_LINK =
  'multi-tool-status-identity-link';
export const IDENTITY_MULTI_TOOL_MODAL_LINK = 'identity-multi-tool-modal-link';
export const WATCH_HIDE_EMAILS_MODAL_LINK = 'watch-hide-emails-modal-link';
export const ASSETS_TABLE_NAME_LINK = 'assets-table-name-link';
export const ASSET_PACKAGE_ADVISORY_MODAL_LINK =
  'asset-package-advisory-modal-link';
export const ASSET_PACKAGE_REPOSITORIES_MODAL_LINK =
  'asset-package-repositories-modal-link';
export const ASSET_PACKAGE_IMPORTERS_MODAL_LINK =
  'asset-package-importers-modal-link';
export const ASSET_PACKAGE_NAME_LINK = 'asset-package-name-link';
export const ASSET_CONTRIBUTORS_NAME_LINK = 'asset-contributors-name-link';
export const ASSET_TEAMS_NAME_LINK = 'asset-teams-name-link';
export const BRANCHES_REPOSITORY_LINK = 'branches-repository-link';
export const EDIT_POLICY_IDENTITY_LINK = 'edit-policy-identity-link';
export const IDENTITIES_TABLE_NAME_LINK = 'identities-table-name-link';
export const IDENTITY_STATUS_MODAL_LINK = 'identity-status-modal-link';
export const IDENTITY_SELF_NAME_LINK = 'identity-self-name-link';
export const IDENTITY_ASSET_TEAMS_MODAL_LINK =
  'identity-asset-teams-modal-link';
export const IDENTITY_ASSET_DETAILS_LINK = 'identity-asset-details-link';
export const IDENTITY_RISK_REASON_POLICY_LINK =
  'identity-risk-reason-policy-link';
export const OPENSOURCE_TABLE_NAME_LINK = 'opensource-table-name-link';
export const OPENSOURCE_CONTRIBUTORS_NAME_LINK =
  'opensource-contributors-name-link';
export const OPENSOURCE_REPOSITORIES_NAME_LINK =
  'opensource-repositories-name-link';
export const OPENSOURCE_REPOSITORY_IMPORTERS_MODAL_LINK =
  'opensource-repository-importers-modal-link';
export const RECOMMENDATION_TYPE_LINK = 'recommendation-type-link';
export const RECOMMENDATION_TEAM_MEMBER_DETAILS_LINK =
  'recommendation-team-member-details-link';
export const RECOMMENDATION_TEAM_MEMBER_NAME_LINK =
  'recommendation-team-member-name-link';
export const RECOMMENDATION_TEAM_DETAILS_LINK =
  'recommendation-team-details-link';
export const RECOMMENDATION_USER_DETAILS_LINK =
  'recommendation-user-details-link';
export const RECOMMENDATION_USER_REPOSITORY_LINK =
  'recommendation-user-repository-link';
export const TEAMS_MEMBERS_MODAL_LINK = 'teams-members-modal-link';
export const TEAMS_RISK_SCORE_LINK = 'teams-risk-score-link';
export const TEAM_MISSING_USERS_MODAL_LINK = 'team-missing-users-modal-link';
export const TEAM_MEMBERS_DETAILS_LINK = 'team-members-details-link';
export const TEAM_ASSETS_DETAILS_LINK = 'team-assets-details-link';
export const MISSING_MODAL_NAME_LINK = 'missing-modal-name-link';

export const REPOSITORIES_CSV_DOWNLOAD = 'repositories-csv-download';
export const REPOSITORY_ACTIVITY_CSV_DOWNLOAD =
  'repository-activity-csv-download';
export const REPOSITORY_BRANCHES_CSV_DOWNLOAD =
  'repository-branches-csv-download';
export const REPOSITORY_COMPLIANCE_CSV_DOWNLOAD =
  'repository-compliance-csv-download';
export const REPOSITORY_EXTERNAL_PACKAGES_CSV_DOWNLOAD =
  'repository-external-packages-csv-download';
export const REPOSITORY_CONTRIBUTORS_CSV_DOWNLOAD =
  'repository-contributors-csv-download';
export const REPOSITORY_PIPELINE_CSV_DOWNLOAD =
  'repository-pipeline-csv-download';
export const COMPLIANCE_OVERVIEW_CSV_DOWNLOAD =
  'compliance-overview-csv-download';
export const COMPLIANCE_DETAILS_CSV_DOWNLOAD =
  'compliance-details-csv-download';
export const COMPLIANCE_POLICY_CSV_DOWNLOAD = 'compliance-policy-csv-download';
export const IDENTITIES_CSV_DOWNLOAD = 'identities-csv-download';
export const IDENTITY_ACCESS_CSV_DOWNLOAD = 'identity-access-csv-download';
export const IDENTITY_ACTIVITY_CSV_DOWNLOAD = 'identity-activity-csv-download';
export const IDENTITY_ALERTS_CSV_DOWNLOAD = 'identity-alerts-csv-download';
export const IDENTITY_AUDIT_TRAIL_CSV_DOWNLOAD =
  'identity-audit-trail-csv-download';
export const IDENTITY_RECOMMENDATIONS_CSV_DOWNLOAD =
  'identity-recommendations-csv-download';
export const IDENTITY_OPENSOURCE_CSV_DOWNLOAD =
  'identity-opensource-csv-download';
export const IDENTITY_PIPELINE_CSV_DOWNLOAD = 'identity-pipeline-csv-download';
export const IDENTITY_SECRETS_CSV_DOWNLOAD = 'identity-secrets-csv-download';
export const OPENSOURCE_DEPENDENCIES_CSV_DOWNLOAD =
  'opensource-dependencies-csv-download';
export const RECOMMENDATION_TEAMS_CSV_DOWNLOAD =
  'recommendation-teams-csv-download';
export const RECOMMENDATION_USERS_CSV_DOWNLOAD =
  'recommendation-users-csv-download';
export const SECRETS_CSV_DOWNLOAD = 'secrets-csv-download';
export const TEAMS_CSV_DOWNLOAD = 'teams-csv-download';
export const TEAM_MEMBERS_CSV_DOWNLOAD = 'team-members-csv-download';
export const TEAM_REPOSITORIES_CSV_DOWNLOAD = 'team-repositories-csv-download';
export const TEAM_RECOMMENDATIONS_CSV_DOWNLOAD =
  'team-recommendations-csv-download';

export const ASSETS_SEARCH_INPUT = 'assets-search-input';
export const ASSET_BRANCHES_SEARCH_INPUT = 'asset-branches-search-input';
export const ASSET_COMPLIANCE_SEARCH_INPUT = 'asset-compliance-search-input';
export const ASSET_EXTERNAL_PACKAGES_SEARCH_INPUT =
  'asset-external-packages-search-input';
export const ASSET_CONTRIBUTORS_SEARCH_INPUT =
  'asset-contributors-search-input';
export const ASSET_TEAMS_SEARCH_INPUT = 'asset-teams-search-input';
export const BRANCHES_SEARCH_INPUT = 'branches-search-input';
export const COMPLIANCE_OVERVIEW_SEARCH_INPUT =
  'compliance-overview-search-input';
export const IDENTITIES_SEARCH_INPUT = 'identities-search-input';
export const IDENTITY_ACCESS_SEARCH_INPUT = 'identity-access-search-input';
export const IDENTITY_ACTIVITY_SEARCH_INPUT = 'identity-activity-search-input';
export const IDENTITY_RECOMMENDATIONS_SEARCH_INPUT =
  'identity-recommendations-search-input';
export const IDENTITY_OPENSOURCE_SEARCH_INPUT =
  'identity-opensource-search-input';
export const OPENSOURCE_DEPENDENCIES_SEARCH_INPUT =
  'opensource-dependencies-search-input';
export const CUSTOM_POLICIES_SEARCH_INPUT = 'custom-policies-search-input';
export const POLICY_LIBRARY_SEARCH_INPUT = 'policy-library-search-input';
export const RECOMMENDATION_TEAMS_SEARCH_INPUT =
  'recommendation-teams-search-input';
export const RECOMMENDATION_USERS_SEARCH_INPUT =
  'recommendation-users-search-input';
export const REPORTING_SEARCH_INPUT = 'reporting-search-input';
export const SECRETS_SEARCH_INPUT = 'secrets-search-input';
export const TEAMS_SEARCH_INPUT = 'teams-search-input';
export const TEAM_MEMBERS_SEARCH_INPUT = 'team-members-search-input';
export const TEAM_REPOSITORIES_SEARCH_INPUT = 'team-repositories-search-input';
export const TEAM_RECOMMENDATIONS_SEARCH_INPUT =
  'team-recommendations-search-input';
export const TEAM_IDLE_USERS_SEARCH_INPUT = 'team-idle-users-search-input';
export const WATCH_HIDE_SEARCH_INPUT = 'watch-hide-search-input';
export const USER_MANAGEMENT_SEARCH_INPUT = 'user-management-search-input';

export const DASHBOARD_RESET_BUTTON = 'dashboard-reset-button';
export const DASHBOARD_IDENTITY_OVERVIEW = 'dashboard-identity-overview';
export const DASHBOARD_IDENTITY_OVERVIEW_CARD =
  'dashboard-identity-overview-card';
export const DASHBOARD_ALERTS_OVERVIEW = 'dashboard-alerts-overview';
export const DASHBOARD_ALERT_OVERVIEW_CARD = 'dashboard-alert-overview-card';
export const ALERTS_VIEW_ALL_BUTTON = 'alerts-view-all-button';
export const DASHBOARD_ASSETS_OVERVIEW = 'dashboard-assets-overview';
export const DASHBOARD_ASSET_OVERVIEW_CARD = 'dashboard-asset-overview-card';
export const DASHBOARD_COMPLIANCE_OVERVIEW = 'dashboard-compliance-overview';
export const DASHBOARD_COMPLIANCE_OVERVIEW_CARD =
  'dashboard-compliance-overview-card';
export const COMPLIANCE_VIEW_ALL_BUTTON = 'compliance-view-all-button';
export const DASHBOARD_TOP_RECOMMENDATIONS_OVERVIEW =
  'dashboard-top-recommendations-overview';
export const DASHBOARD_TOP_RECOMMENDATIONS_CARD =
  'dashboard-top-recommendations-card';
export const RECOMMENDATIONS_VIEW_ALL_BUTTON =
  'recommendations-view-all-button';
export const DASHBOARD_POLICY_CATEGORY_CARD = 'dashboard-policy-category-card';
export const DASHBOARD_POLICY_SEVERITY_CARD = 'dashboard-policy-severity-card';
export const DASHBOARD_MANAGE_SECTIONS_BUTTON =
  'dashboard-manage-sections-menu';
export const ONBOARDING_CHECKLIST = 'onboarding-checklist';
export const ONBOARDING_CHECKLIST_PROGRESS = 'onboarding-checklist-progress';
export const ONBOARDING_CHECKLIST_ITEM = 'onboarding-checklist-item';
export const ONBOARDING_CHECKLIST_BUTTON = 'onboarding-checklist-button';
export const POLICIES_GROUPING_MENU = 'policies-grouping-menu';
export const POLICIES_GROUPING_MENU_ITEM = 'policies-grouping-menu-item';
export const DASHBOARD_POLICIES_TABS = 'dashboard-policies-tabs';
export const DASHBOARD_POLICIES_TAB = 'dashboard-policies-tab';

export const VISIBILITY_SHOW_BUTTON = 'visibility-show-button';
export const VISIBILITY_NONE_BUTTON = 'visibility-none-button';
export const VISIBILITY_HIDE_BUTTON = 'visibility-hide-button';

export const DATE_PICKER_BUTTON = 'date-picker-button';
export const DATE_PICKER_MENU_ITEM = 'date-picker-menu-item';
export const DATE_PICKER_CUSTOM_PERIOD = 'date-picker-custom-period';
export const CUSTOM_DATE_RANGE_PICKER = 'custom-date-range-picker';
export const CANCEL_DATE_RANGE_INPUT = 'cancel-date-range-input';
export const APPLY_DATE_RANGE_INPUT = 'apply-date-range-input';

export const TABLE_PLOT_SWITCH_TABS = 'table-plot-switch-tabs';
export const SWITCH_TO_TABLE_TAB = 'switch-to-table-tab';
export const SWITCH_TO_PLOT_TAB = 'switch-to-plot-tab';

export const POLICY_LIBRARY_TAB = 'policy-library-tab';
export const CUSTOM_POLICIES_TAB = 'custom-policies-tab';
export const IDENTITY_DETAILS_ACCESS_TAB = 'identity-details-access-tab';
export const IDENTITY_DETAILS_REQUESTS_TAB = 'identity-details-requests-tab';
export const IDENTITY_DETAILS_ACTIVITIES_TAB =
  'identity-details-activities-tab';
export const IDENTITY_DETAILS_ALERTS_TAB = 'identity-details-alerts-tab';
export const IDENTITY_DETAILS_OPENSOURCE_TAB =
  'identity-details-opensource-tab';
export const IDENTITY_DETAILS_SECRETS_TAB = 'identity-details-secrets-tab';
export const IDENTITY_DETAILS_RECOMMENDATIONS_TAB =
  'identity-details-recommendations-tab';
export const IDENTITY_ACTIVITY_SOURCECODE_TAB =
  'identity-activity-sourcecode-tab';
export const IDENTITY_ACTIVITY_PIPELINE_TAB = 'identity-activity-pipeline-tab';
export const IDENTITY_ACTIVITY_AUDITTRAIL_TAB =
  'identity-activity-audittrail-tab';
export const ASSET_DETAILS_CONTRIBUTORS_TAB = 'asset-details-contributors-tab';
export const ASSET_DETAILS_ACTIVITIES_TAB = 'asset-details-activities-tab';
export const ASSET_DETAILS_BRANCHES_TAB = 'asset-details-branches-tab';
export const ASSET_DETAILS_EXTERNAL_PACKAGES_TAB =
  'asset-details-external-packages-tab';
export const ASSET_DETAILS_CONTAINERS_TAB = 'asset-details-containers-tab';
export const ASSET_DETAILS_COMPLIANCE_TAB = 'asset-details-compliance-tab';
export const ASSET_DETAILS_COMPLIANCE_NIST_TAB =
  'asset-details-compliance-nist-tab';
export const ASSET_DETAILS_COMPLIANCE_OPENSSF_TAB =
  'asset-details-compliance-openssf-tab';
export const ASSET_ACTIVITY_SOURCECODE_TAB = 'asset-activity-sourcecode-tab';
export const ASSET_ACTIVITY_PIPELINE_TAB = 'asset-activity-pipeline-tab';
export const ASSET_CONTRIBUTORS_USERS_TAB = 'asset-contributors-users-tab';
export const ASSET_CONTRIBUTORS_TEAMS_TAB = 'asset-contributors-teams-tab';
export const JIT_LISTING_TAB = 'jit-listing-tab';
export const JIT_REQUESTS_TAB = 'jit-requests-tab';
export const OPENSOURCE_DETAILS_REPOS_TAB = 'opensource-details-repos-tab';
export const OPENSOURCE_DETAILS_HEALTH_ANALYSIS_TAB =
  'opensource-details-health-analysis-tab';
export const OPENSOURCE_CONTRIBUTOR_REPUTATION_TAB =
  'opensource-contributor-reputation-tab';
export const OPENSOURCE_CONTRIBUTOR_VULNERABILITIES_TAB =
  'opensource-contributor-vulnerabilities-tab';
export const RECOMMENDATIONS_SINGLE_TEAM_TAB =
  'recommendations-single-team-tab';
export const RECOMMENDATIONS_MULTI_TEAM_TAB = 'recommendations-multi-team-tab';
export const RECOMMENDATIONS_IDENTITIES_TAB = 'recommendations-identities-tab';
export const RECOMMENDATIONS_ASSETS_TAB = 'recommendations-assets-tab';
export const RECOMMENDATIONS_TEAMS_TAB = 'recommendations-teams-tab';
export const TEAM_DETAILS_REPOS_TAB = 'team-details-repos-tab';
export const TEAM_DETAILS_MEMBERS_TAB = 'team-details-members-tab';
export const TEAM_DETAILS_RECOMMENDATIONS_TAB =
  'team-details-recommendations-tab';
export const TEAM_DETAILS_MAPPINGS_TAB = 'team-details-mappings-tab';
export const USER_MANAGEMENT_USERS_TAB = 'user-management-users-tab';
export const USER_MANAGEMENT_CONFIGURE_TAB = 'user-management-configure-tab';

export const ENTITY_GRAPH_MODAL_PERMISSIONS_MENU =
  'entity-graph-modal-permissions-menu';
export const ENTITY_GRAPH_MODAL_PERMISSION_ITEM =
  'entity-graph-modal-permission-item';
export const ENTITY_GRAPH_MODAL_USED_BUTTON = 'entity-graph-modal-used-button';

export const ALERTS_APPLIES_TO_CHIP = 'alerts-applies-to-chip';
export const REPORTS_REFRESH_DATA_BUTTON = 'reports-refresh-data-button';
export const REPORTS_FILE_PREVIEW_BUTTON = 'reports-file-preview-button';
export const REPORTS_FILE_DOWNLOAD_BUTTON = 'reports-file-download-button';
export const REPORTS_SEND_EMAIL_BUTTON = 'reports-send-email-button';
export const REPORTS_FILE_DELETE_BUTTON = 'reports-file-delete-button';

export const TEAMS_TABLE_NAME_LINK = 'teams-table-name-link';
export const TEAMS_MODAL_TEAM_LINK = 'teams-modal-team-link';
export const TEAM_RECOMMENDATIONS_ACTION_DROPDOWN =
  'team-recommendations-action-dropdown';
export const TEAM_RECOMMENDATIONS_ACTION_ITEM =
  'team-recommendations-action-item';
export const TEAM_RECOMMENDATIONS_ASSETS_CHECKBOX =
  'team-recommendations-assets-checkbox';
export const TEAM_RECOMMENDATIONS_USERS_CHECKBOX =
  'team-recommendations-users-checkbox';
export const TEAM_RECOMMENDATIONS_GRAPH_MODAL =
  'team-recommendations-graph-modal';
export const TEAM_RECOMMENDATIONS_PERMISSIONS_GRAPH =
  'team-recommendations-permissions-graph';

export const IDENTITY_INFO_PROFILE_LINK = 'identity-info-profile-link';
export const IDENTITY_MULTI_TOOL_MODAL = 'identity-multi-tool-modal';
export const INFO_COMPONENT_PROFILE_LINK = 'info-component-profile-link';
export const RISK_REASON_MODAL_LINK = 'risk-reason-modal-link';
export const IDENTITY_ACTIVITY_COMMIT_CELL = 'identity-activity-commit-cell';
export const IDENTITY_PIPELINE_BUILD_CELL = 'identity-pipeline-build-cell';
export const IDENTITY_PIPELINE_COMMIT_CELL = 'identity-pipeline-commit-cell';
export const IDENTITY_DETAILS_JSON_MODAL_LINK =
  'identity-details-json-modal-link';
export const IDENTITY_ALERTS_APPLIES_TO_CHIP =
  'identity-alerts-applies-to-chip';
export const IDENTITY_SECRETS_FILE_CELL = 'identity-secrets-file-cell';
export const IDENTITY_RECOMMENDATIONS_GRAPH_LINK =
  'identity-recommendations-graph-link';
export const IDENTITY_RECOMMENDATIONS_ACTION_DROPDOWN =
  'identity-recommendations-action-dropdown';
export const IDENTITY_RECOMMENDATIONS_ACTION_ITEM =
  'identity-recommendations-action-item';
export const IDENTITY_RECOMMENDATIONS_GRAPH_MODAL =
  'identity-recommendations-graph-modal';
export const IDENTITY_RECOMMENDATIONS_PERMISSIONS_GRAPH =
  'identity-recommendations-permissions-graph';
export const IDENTITY_RECOMMENDATIONS_ASSET_CHECKBOX =
  'identity-recommendations-asset-checkbox';

export const RECOMMENDATION_USERS_ASSET_CHECKBOX =
  'recommendation-users-asset-checkbox';
export const RECOMMENDATION_USERS_GRAPH_LINK =
  'recommendation-users-graph-link';
export const RECOMMENDATION_USERS_ACTION_DROPDOWN =
  'recommendation-users-action-dropdown';
export const RECOMMENDATION_USERS_ACTION_ITEM =
  'recommendation-users-action-item';
export const RECOMMENDATION_USERS_GRAPH_MODAL =
  'recommendation-users-graph-modal';
export const RECOMMENDATION_USERS_PERMISSIONS_GRAPH =
  'recommendation-users-permissions-graph';

export const RECOMMENDATION_DETAILS_SIMULATION_COMPONENT =
  'recommendation-details-simulation-component';
export const RECOMMENDATION_DETAILS_SIMULATION_OPTION =
  'recommendation-details-simulation-option';
export const RECOMMENDATION_DETAILS_SIMULATION_BUTTON =
  'recommendation-details-simulation-button';

export const ASSET_CONTRIBUTORS_CHECKBOX = 'asset-contributors-checkbox';
export const ASSET_PIPELINE_BUILD_CELL = 'asset-pipeline-build-cell';

export const OPENSOURCE_HEALTH_CHIP = 'opensource-health-chip';
export const OPENSOURCE_DETAILS_README_LINK = 'opensource-details-readme-link';
export const OPENSOURCE_DETAILS_PACKAGE_LINK =
  'opensource-details-package-link';
export const OPENSOURCE_DETAILS_ADVISORIES_MODAL_LINK =
  'opensource-details-advisories-modal-link';
export const OPENSOURCE_INFO_COMPONENT_MODAL =
  'opensource-info-component-modal';
export const OPENSOURCE_REPOS_STATUS_SELECT = 'opensource-repos-status-select';
export const OPENSOURCE_REPOS_DEPENDENCY_TREE_LINK =
  'opensource-repos-dependency-tree-link';
export const OPENSOURCE_REPOS_ADVISORIES_MODAL_LINK =
  'opensource-repos-advisories-modal-link';
export const OPENSOURCE_ADVISORIES_MODAL_ACCORDIAN =
  'opensource-advisories-modal-accordian';

export const POLICY_LIBRARY_SEVERITY_FILTER = 'policy-library-severity-filter';
export const ALERTS_SEVERITY_FILTER = 'alerts-severity-filter';
export const RISK_CHART_SEVERITY_FILTER = 'risk-chart-severity-filter';
export const OPENSOURCE_REPOS_SEVERITY_FILTER =
  'opensource-repos-severity-filter';
export const CUSTOM_POLICIES_SEVERITY_FILTER =
  'custom-policies-severity-filter';

export const ASSET_CONTAINERS_OPTIONS_DROPDOWN =
  'asset-containers-options-dropdown';
export const ASSET_EXTERNAL_PACKAGES_DOWNLOAD_SBOM =
  'asset-external-packages-download-sbom';
export const ASSET_CONTRIBUTORS_ACTIONS_DROPDOWN =
  'asset-contributors-actions-dropdown';
export const POLICY_ACTIONS_OPTIONS_DROPDOWN =
  'policy-actions-options-dropdown';
export const IDENTITY_ACCESS_OPTIONS_DROPDOWN =
  'identity-access-options-dropdown';
export const IDENTITY_RECOMMENDATIONS_OPTIONS_DROPDOWN =
  'identity-recommendations-options-dropdown';
export const ASSET_RECOMMENDATIONS_OPTIONS_DROPDOWN =
  'asset-recommendations-options-dropdown';

export const ASSET_RECOMMENDATIONS_CHECKBOX = 'asset-recommendations-checkbox';
export const JIT_LISTING_OPTIONS_DROPDOWN = 'jit-listing-options-dropdown';
export const JIT_REQUESTS_OPTIONS_DROPDOWN = 'jit-requests-options-dropdown';
export const CUSTOM_POLICIES_OPTIONS_DROPDOWN =
  'custom-policies-options-dropdown';
export const RECOMMENDATION_TEAMS_OPTIONS_DROPDOWN =
  'recommendation-teams-options-dropdown';
export const RECOMMENDATION_USERS_OPTIONS_DROPDOWN =
  'recommendation-users-options-dropdown';
export const TEAM_RECOMMENDATIONS_OPTIONS_DROPDOWN =
  'team-recommendations-options-dropdown';
export const USER_MANAGEMENT_OPTIONS_DROPDOWN =
  'user-management-options-dropdown';
export const CONSOLE_PAGE_HEADER = 'console-page-header';
export const CONSOLE_MAIN_TABLE = 'console-main-table';
